import { Container, Placeholder } from "react-bootstrap";

function Contacts() {
  return (
    <div className="border-top mt-3 ">
      <Container id="contacts" className="text-center">
        <h3>Контакты</h3>
      </Container>
      <Container id="Contacts" className="text-center fs-5">
        ООО «ЗАПСИБУГЛЕПРОЕКТ» Почтовый адрес: 650002, Кемеровская
        область-Кузбасс, г. Кемерово, б-р имени академика Л.С. Барабаша, 1, оф.
        208 <br />
        Телефон: +7 (3842) 339-585 <br />
        E-mail:<a href="mailto: mail@zapsibup.ru">mail@zapsibup.ru</a>
      </Container>
    </div>
  );
}

export default Contacts;
