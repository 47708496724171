import Button from "react-bootstrap/Button";
import "./App.css";
import { HashLink as Link } from "react-router-hash-link";
import {
  Carousel,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  Placeholder,
  Stack,
} from "react-bootstrap";
import About from "./About";
import Services from "./Services";
import Contacts from "./Contacts";

function App() {
  return (
    <Container className="p-3">
      <Container className="p-4 mb-4 rounded-4">
        <Stack direction="horizontal" className="d-flex" gap={2}>
          <a href="/">
            <img
              className="d-block"
              src="logo.png"
              alt="First slide"
              width={250}
              height={170}
            />
          </a>
          <h1 className="header">
            <a href="/">ЗАПСИБУГЛЕПРОЕКТ</a>
          </h1>
        </Stack>
        <Navbar className="border-top " bg="light" data-bs-theme="light">
          <Container>
            <Nav className="nav-fill w-100">
              <Link to="#about" className="nav-link border-end">
                О компании
              </Link>
              <Link to="#services" className="nav-link">
                Услуги
              </Link>
              <Link to="#contacts" className="nav-link border-start">
                Контакты
              </Link>
            </Nav>
          </Container>
        </Navbar>
        <About />
        <Services />
        <Contacts />
      </Container>
    </Container>
  );
}

export default App;
