import { ListGroup } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";

function Services() {
  return (
    <div>
      <Container id="services" className="border-top text-center">
        <h3>Услуги</h3>
      </Container>
      <Container className="fs-5">
        <p className="text-center">
          <b>ООО «ЗАПСИБУГЛЕПРОЕКТ»</b> осуществляет разработку следующей
          документации
        </p>
        <ListGroup as="ol">
          <ListGroup.Item as="li">
            Технические перевооружения опасных производственных объектов (с
            прохождением экспертизы промбезопасности).
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты аэрогазового контроля.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты многофункциональной системы безопасности.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты подвесной монорельсовой дороги.
          </ListGroup.Item>
          <ListGroup.Item as="li">Проекты дегазации.</ListGroup.Item>
          <ListGroup.Item as="li">Проекты проветривания.</ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты комплексного обеспыливания и пылевзрывозащиты.
          </ListGroup.Item>
          <ListGroup.Item as="li">Проекты водоотлива.</ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты противопожарной защиты.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Технические проекты (с согласованием в ЦКР-ТПИ Роснедр).
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты производства маркшейдерских работ (с согласованием с
            Ростехнадзором).
          </ListGroup.Item>
          <ListGroup.Item as="li">Проекты горных отводов.</ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты установления барьерных целиков.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты построения предохранительных целиков под капитальные горные
            выработки.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты мер охраны объектов поверхности от вредного влияния горных
            работ.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты построения предохранительных целиков объектов поверхности от
            вредного влияния подземных горных работ.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты прорезки предохранительных целиков подготовительными горными
            выработками.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Проекты построения границ опасной зоны под затопленное пространство,
            геологическое нарушение, скважины (различного назначения).
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Построение зон Повышенного Горного Давления (ПГД).
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Технико-экономическое обоснование (ТЭО) кондиций (с согласованием в
            ГКЗ Роснедр).
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Технико-экономическое обоснование (ТЭО) списания запасов.
          </ListGroup.Item>
          <ListGroup.Item as="li">
            Инвентаризация выбросов парниковых газов, подготовка необходимой
            отчетности.
          </ListGroup.Item>
          <ListGroup.Item as="li">Оценка углеродного следа.</ListGroup.Item>
        </ListGroup>
      </Container>
    </div>
  );
}
export default Services;
