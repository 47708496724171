import Container from "react-bootstrap/esm/Container";

function About() {
  return (
    <div>
      <Container id="about" className="border-top  text-center">
        <h3>О компании</h3>
      </Container>
      <Container className="text-center fs-5">
        <b>Компания «ЗАПСИБУГЛЕПРОЕКТ»</b> осуществляет разработку проектной
        документации для предприятий угледобывающей и горно-рудной
        промышленности , выполняет работы по инвентаризации выбросов парниковых
        газов, оценке углеродного следа. Привлекаемые для выполнения работ
        специалисты компании обладают необходимым опытом, знаниями и
        компетенциями в предметной области. Это позволяет обеспечивать
        качественное и своевременное выполнение поставленных задач.
      </Container>
    </div>
  );
}

export default About;
